.swiper-pagination {
    margin-top: 20rem;
}

.swiper-pagination-bullet {
    background-color: #ac6b34;
}

.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    top: 35%;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #753a0a;
}